<template>
    <div id="Mail">
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in">
                    <p class="su_tit">당첨자 중복 검사</p>
                </div>
            </div>
        </div>
        <div class="sub_content">
            <div class="main">
                <input type="radio" id="tab-1" name="show" checked/>
                <input type="radio" id="tab-2" name="show" />
                <input type="radio" id="tab-3" name="show" />
                <input type="radio" id="tab-4" name="show" />
                <input type="radio" id="tab-5" name="show" />
                <input type="radio" id="tab-6" name="show" />
                <input type="radio" id="tab-7" name="show" />
                <div class="tab">
                    <label for="tab-1">17A</label>
                    <label for="tab-2">17B</label>
                    <label for="tab-3">33A</label>
                    <label for="tab-4">33B</label>
                    <label for="tab-5">17A-특별</label>
                    <label for="tab-6">33A-특별</label>
                    <label for="tab-7">33B-특별</label>
                </div>
                <div class="content">
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get17A" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get17B" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get33A" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get33B" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get17AS" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get33AS" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="content-dis">
                        <div class="titlebox"> 
                            <div class="check">
                                <table>
                                    <thead>
                                        <tr class="titlebox"> 
                                            <th class="text">이름</th><th class="text">전화번호</th>
                                            <th class="text">17A</th><th class="text">17B</th><th class="text">33A</th><th class="text">33B</th>
                                            <th class="text">17A-특별</th><th class="text">33A-특별</th><th class="text">33B-특별</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(user,index) in users" :key="index">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                        </tr> -->
                                        <tr v-for="user in get33BS" :key="user.user_number" :class="{duplicated: isDuplicated(user)}">
                                            <td> {{user.user_name}} </td>
                                            <td> {{user.user_number}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33a)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33b)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_17as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33as)}} </td>
                                            <td> {{boolToString(user.is_duplicate_in_33bs)}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <form  name="Upload" id="Upload">
                    <div class="form-group">
                        <label for="exampleFormControlFile1">파일 선택해주세요</label>
                        <input type="file" ref="file" name="file" id="file" @change="checkDuplicate"/>
                        <!-- <button type="button" class="checkDuplicateBtn" @click="checkDuplicate()">중복확인</button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import axios from 'axios';

export default {

    data() {
        return {
            users: [],
        }
    },
    methods: {
        checkDuplicate(){
            console.log(this.$refs.file.files[0])
        
            const formData = new FormData()
            formData.append("file", this.$refs.file.files[0]);

			axios.post('https://duckyoung2016.com:8080/tools/checkDuplicate', formData, { headers: { 'Content-Type': 'multipart/form-data' }

			}).then(response => {
				if(response.data.statusCode == 200) {
                    this.users = response.data.result
                    console.log(this.users)	
				}else {	
					alert("전송 실패");
					console.log(response)
				}
			}).catch(err => {
				console.log(err);
            });
        },
        boolToString(bool) {
            if(bool) {
                return "중복"
            } else {
                return ""
            }
        },
        isDuplicated(user) {
            return user.is_duplicate_in_17a || user.is_duplicate_in_17b || user.is_duplicate_in_33a || user.is_duplicate_in_33b || user.is_duplicate_in_17as || user.is_duplicate_in_33as || user.is_duplicate_in_33bs
        }
    },
    computed: {
        get17A() {
            return this.users.filter(user => user.is_duplicate_in_17a === null)
        },
        get17B() {
            return this.users.filter(user => user.is_duplicate_in_17b === null)
        },
        get33A() {
            return this.users.filter(user => user.is_duplicate_in_33a === null)
        },
        get33B() {
            return this.users.filter(user => user.is_duplicate_in_33b === null)
        },
        get17AS() {
            return this.users.filter(user => user.is_duplicate_in_17as === null)
        },
        get33AS() {
            return this.users.filter(user => user.is_duplicate_in_33as === null)
        },
        get33BS() {
            return this.users.filter(user => user.is_duplicate_in_33bs === null)
        },
    }
}
</script>

<style lang="scss">
#Mail {position: relative; width: 100%;
    .inner{overflow: hidden;
        .sub_visual.sub1bg{background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../assets/img/menu_background.png") 0% 0% no-repeat padding-box; opacity: 0.9; width: 100%; height: 420px; background-size: cover;
            .in{width:100%; text-align: center; padding-top: 250px;
                .su_tit{text-align: center; font: normal normal bold 24px/29px GyeonggiTitleVOTF; letter-spacing: 0px; color: #FFFFFF; opacity: 1;}
            } 
        }
    }
    .sub_content{
        .main input[type=radio] { display: none; }
        #tab-1:checked ~ .tab label:nth-child(1),
        #tab-2:checked ~ .tab label:nth-child(2),
        #tab-3:checked ~ .tab label:nth-child(3), 
        #tab-4:checked ~ .tab label:nth-child(4),
        #tab-5:checked ~ .tab label:nth-child(5), 
        #tab-6:checked ~ .tab label:nth-child(6), 
        #tab-7:checked ~ .tab label:nth-child(7) {box-shadow: inset 0 -3px 0 #002a9b;}
        .content > div { display: none;}

        #tab-1:checked ~ .content div:nth-child(1),
        #tab-2:checked ~ .content div:nth-child(2),
        #tab-3:checked ~ .content div:nth-child(3),
        #tab-4:checked ~ .content div:nth-child(4),
        #tab-5:checked ~ .content div:nth-child(5),
        #tab-6:checked ~ .content div:nth-child(6),
        #tab-7:checked ~ .content div:nth-child(7) { display: block;}
        .main { margin: 0 auto; max-width: 1280px;}
        .tab { overflow: hidden; border-bottom: 1px solid #E0E0E0; opacity: 1; text-align: center;}
        .tab label {width: 160px; font: normal normal normal 20px/29px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; cursor: pointer;text-align: center;text-align: center; padding: 15px 0; text-transform: uppercase; user-select: none; -webkit-user-select: none;}
        .tab label:visited{color: #002A9B;} 
        .content {min-height: 600px;}
        .content > div{ line-height: 1.5; font-size: 17px;}

        .check{
            table{width: 100%; margin-top: 30px; 
                thead{
                    .titlebox{margin-top: 10px; text-align: center;
                        .text{font-size: 24px;}
                    }
                    th{width: 10%;}
                }
                tbody{
                    td{border-top: 1px solid gray; text-align: center; height: 50px;}
                }
            }
        }
        form{height: 100px; margin-left: 10px;}
    }
}
.duplicated {background-color: rgb(255, 199, 206); color: red}

</style>