<template>
  <div id="app">
      <Header/>
      <router-view :contract_type="contract_type" />
      <!-- <PANO :width="1000" :height="500" :imageSrc='"33B.png"'></PANO> -->
      <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

// import PANO from "@/components/PANOLENS_Viewer"

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    // PANO
  },
  data() {
    return {
      contract_type: "일반공급"
    }
  }
}
</script>

<style>

</style>
