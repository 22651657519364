import Vue from 'vue'
import VueRouter from 'vue-router'

// 연결 컴포넌트
import Main from '@/view/Main.vue'; // 메인

import Guidance from '@/view/Guidance.vue'; // 단지안내
import Overview from '@/view/Overview.vue'; // 주택개요
import Store from '@/view/Store.vue'; // 상가

import Dangcheom from '@/view/Result/Dangcheom.vue' // 당첨결과 o
import Failure from '@/view/Result/Failure.vue' // 당첨결과 X
import checkDuplicate from '@/view/checkDuplicate.vue' //당첨자중복검사

// import pano from '@/components/PANOLENS_Viewer.vue'

Vue.use(VueRouter);


const routes = [
    {
        path: '/',
        name: '/Main',
        component: Main
    },
    // 당첨결과 O
    {
        path: '/Dangcheom',
        name: 'Dangcheom',
        component: Dangcheom
    },
    // 당첨결과 X
    {
        path: '/Failure',
        name: 'Failure',
        component: Failure
    },
    // 당첨자중복검사
    {
        path: '/checkDuplicate',
        name: 'checkDuplicate',
        component: checkDuplicate
    },
    // 단지안내
    {
        path: '/Guidance',
        name: 'Guidance',
        component: Guidance
    },
    // 주택개요
    {
        path: '/overview',
        name: 'overview',
        component: Overview
    },
    // 상가
    {
        path: '/Store',
        name: 'Store',
        component: Store
    },
    // 계약안내
    {
        path: '/normal',
        name: 'normal',
        component: () => import('@/view/Normal.vue'),
        // props: true 
    },
    {
        path: '/special',
        name: 'special',
        component: () => import('@/view/Special.vue'),
        props: true 
    },
    {
        path: '/winner',
        name: 'winner',
        component: () => import('@/view/Winner.vue'),
    },
    // 고객센터
    {
        path: '/notice',
        name: 'notice',
        component: () => import('@/view/Notice.vue'),
    },
    {
        path: '/data',
        name: 'data',
        component: () => import('@/view/Data.vue'),
    },
    {
        path: '/FAQ',
        name: 'FAQ',
        component: () => import('@/view/FAQ.vue'),
    },
    {
        path: '/customer',
        name: 'customer',
        component: () => import('@/view/Customer.vue'),
    },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
    scrollBehavior(to) {
        if(to.hash) {
            return {selector: to.hash};
        }
        return {x: 0, y: 0}
    },
})

export default router
