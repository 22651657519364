<template>
    <transition name="modal" id="modal">
        <div class="mask">
            <div class="wrapper">
                <div class="container">
                    <div class="header">
                        <slot name="header">
                            <div class="closeBtn" @click="$emit('close-modal')"></div>
                        </slot>
                    </div>
                    <div class="body">
                        <slot name="body">
                        <div id="vr"></div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
  <!-- <div id="modal" ref="modal"> -->
    
    <!-- <div id="overlay">
      <div class="closeBtn" :style="{left: 1520 + 'px', top: 85 + 'px'}" @click="$emit('close-modal')"></div>
    </div>
    <div id="vr">
    </div> -->
  <!-- </div> -->
</template>

<script>

import * as PANOLENS from "panolens"

export default {
  props: {
    width: {
      type: Number,
      default: 1920,
    },
    height: {
      type: Number,
      default: 920,
    },
    imageSrc: {
      type: String,
      default: ""
    },    
  },
  data() {
    return {
    }
  },
  methods: {
    init() {
      var vr = document.querySelector("#vr");
      var panorama = new PANOLENS.ImagePanorama(require("@/assets/VR/" + this.imageSrc));
      var viewer = new PANOLENS.Viewer({controlButtons: ['fullscreen'], container: vr});

      viewer.add(panorama);

      viewer.container.style.width = this.width + "px";
      viewer.container.style.height = this.height + "px";
      viewer.onWindowResize(this.width, this.height);

      
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style>
.mask{position: fixed; z-index: 9999; top: 0; left: 0; width: 100%; height: 100%; background-color: rgb(0 0 0 / 70%); display: table; transition: opacity 0.3 ease; }
.wrapper{display: table-cell; vertical-align: middle; }
.container{width: 1200px; max-width: 1200px; height: 675px; margin: 0 auto; background-color: rgba(255, 255, 255, 0); border-radius: 2px; transition: opacity 0.3 ease;}
.header{display: flex; justify-content: flex-end; padding: 0px; border-bottom: 0px; width: 102%; height: 70px;}
.body{padding: 0px;}

/* #modal, #overlay {width: 100%; height: 100%; position: fixed; left: 0; right: 0; z-index: 10;} */
#overlay {opacity: 0.8; background-color: black; width: 100%;}
#vr {position: relative; margin:auto; z-index: 10;  opacity: 1;  background-color: white;}
.closeBtn {position: absolute; cursor: pointer;}
.closeBtn:after {content: "\00d7"; font-size: 50px; color:white; z-index: 11; opacity: 1;}
/* #vr {width: 100%} */


</style>
