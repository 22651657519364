<template>
    <header id="header" :class="[$route.path =='/' ? 'hideHeader' : 'showHeader']">
    <!-- <header id="header"> -->
        <!-- <div class="top"> -->
        <div class="top">
            <div class="contents">
                <div class="logo"><router-link :to="{path: '/'}"><img :src="require('@/assets/icon/구산주택.png')"></router-link></div>
                <div class="category">
                    <ul class="topMenu" id="topMenu">
                    <!-- <ul class="topMenu" id="topMenu" @mouseover="over()" @mouseleave="overFalse()" @click="overFalse()"> -->
                        <!-- 단지 안내  -->
                        <li class="dropdown">
                            <router-link :to="{name: 'Guidance'}"><div :style="{'color': '#242424'}" class="titleMenu">단지안내</div></router-link>
                            <div class="dropdown-content" :class="{'showMenu': isHover}">
                                <div class="position">
                                    <ul>
                                        <li><router-link :to="{path: '/Guidance'}">단지안내</router-link></li>
                                        <!-- <li><router-link :to="{path: '/Guidance', hash: '#facilities'}">편의시설</router-link></li> -->
                                        <li><router-link :to="{path: '/Guidance', hash: '#around'}">단지특성</router-link></li>
                                        <li><router-link :to="{path: '/Guidance', hash: '#map'}">오시는길</router-link></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <!-- 청년주택  -->
                        <li class="dropdown">
                            <router-link :to="{name: 'overview'}"><div :style="{'color': '#242424'}" class="titleMenu">청년주택</div></router-link>
                            <div class="dropdown-content" :class="{'showMenu': isHover}">
                                <div class="position">
                                    <ul>
                                        <li><router-link :to="{name: 'overview'}">주택개요</router-link></li>
                                        <li><router-link :to="{path: '/overview', hash: '#household'}">세대정보</router-link></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <!-- 상가 -->
                        <li class="dropdown" style="width: 179px">
                            <router-link :to="{path: 'Store'}"><div :style="{'color': '#242424'}" class="titleMenu">상가/편의시설</div></router-link>
                            <div class="dropdown-content" style="width: 179px" :class="{'showMenu': isHover}">
                                <div class="position">
                                    <ul>
                                        <li style="width: 179px"><router-link :to="{path: '/Store'}">상가개요</router-link></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>  
                        </li>
                        <!-- 계약안내 -->
                        <li class="dropdown">
                            <router-link :to="{name: 'normal', params: {type: '일반공급'}}"><div :style="{'color': '#242424'}" class="titleMenu">계약안내</div></router-link>
                            <!-- <router-link to="contract" class="dropbtn">계약안내</router-link> -->
                            <div class="dropdown-content" :class="{'showMenu': isHover}">
                                <div class="position">
                                    <ul>
                                        <!-- <li><router-link to="{name: 'special', params: {name: '일반공급', age: 1},}"> 일반공급</router-link></li> -->
                                        <!-- <li @click="moveWithParams('일반공급')">일반공급</li>
                                        <li @click="moveWithParams('특별공급')">특별공급</li>
                                        <li @click="moveWithParams('당첨자')">당첨자조회<li> -->
                                        <li @click="moveWithParams('일반공급')"><router-link :to="{name: 'normal', params: {type: '일반공급'}}" >일반공급</router-link></li>
                                        <li @click="moveWithParams('특별공급')"><router-link :to="{name: 'normal', params: {type: '특별공급'}}" >특별공급</router-link></li>
                                        <li @click="moveWithParams('당첨자')"><router-link :to="{name: 'normal', params: {type: '당첨자'}}" >당첨자조회</router-link></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <!-- 고객센터  -->
                        <li class="dropdown">
                            <router-link :to="{path: 'notice'}"><div :style="{'color': '#242424'}" class="titleMenu">고객센터</div></router-link>
                            <div class="dropdown-content" :class="{'showMenu': isHover}">
                                <div class="position">
                                    <ul class="sub">
                                        <li @click="moveWithParams('공지사항')"><router-link :to="{name: 'notice', params: {type: '공지사항'}}">공지사항</router-link></li>
                                        <li @click="moveWithParams('자료실')"><router-link :to="{name: 'notice', params: {type: '자료실'}}">자료실</router-link></li>
                                        <li @click="moveWithParams('FAQ')"><router-link :to="{name: 'notice', params: {type: 'FAQ'}}">FAQ</router-link></li>
                                        <li @click="moveWithParams('관심고객등록')"><router-link :to="{name: 'notice', params: {type: '관심고객등록'}}">관심고객등록</router-link></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="bg">
                    <div class="line">
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            isHover: false,
            isHome: true,
        }
    },
    mounted() { 
        if(this.$route.path != '/') {
            this.isHome = false
            // console.log("isHome: false");
        }
    },
    methods: {
      over() {
          this.isHover = true;
      },
      overFalse() {
          this.isHover = false;
      },
      moveWithParams(type) {
        //   this.$router.push({name: name, params:{type: type}}).catch(() => {
        //       this.$router.go(this.$router.currentRouter)
        //   })
        //   this.$router.push({name: 'normal', params: {type: type}}).catch(err => {
        //       console.log(err)
        //   })
        this.$parent.contract_type = type;
      }
    },
    watch: {
        $route(to) {
            this.isHome = to.name == "Main";
        }
    }
    
}
</script>


<style>
.hideHeader {border-bottom: none}
.showHeader {border-bottom: 1px solid #cac9c9;}

#header{ position: absolute; z-index: 3; width: 100%; } 
.top{ position: relative; display: inline-block; width: 100%; text-align: center; height: 70px; margin: 0 auto;}
.contents{ text-align: center; margin: 0 auto; height: 70px; max-width: 1333px; display: flex; align-content: center; justify-content: space-between;}
.logo{opacity: 1; z-index: 1; line-height: 70px; }

.topMenu{ width: 100%; margin: 0 auto; box-sizing: border-box; cursor : pointer;}
.topMenu li {position: relative; float: left; display: inline-block; box-sizing: border-box; width: 125px; text-decoration: none}
.topMenu li a {color: #ffffff; text-decoration: none; font: normal normal 300 20px/29px Noto Sans KR; letter-spacing: 0px; opacity: 1; text-align: center;}
/* .dropdown-content div ul li a {font: normal normal 500 16px/18px Noto Sans KR;} */

.position ul{padding-left: 0;}

.dropbtn {color: #333333; font: normal normal 18px/27px Noto Sans KR; border: none; width: 300px; cursor : pointer; opacity: 1; text-align: center;}
.dropdown { position: relative; display: inline-block; width: 100px;}
.dropdown:hover {box-shadow: inset 0 -3px 0 #153E06;}
.dropdown-content {display: none; position: absolute; z-index: 1;  width: 125px; background-color: rgba( 255, 255, 255, 0 );}
.dropdown-content .position {position: relative; display: inline-block; max-width: 1200px; float: left; width: 100%; box-sizing: border-box; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 0.8;}
.dropdown-content li { color: black;  text-decoration: none; display: inline-block; position: relative; text-align: center; box-sizing: border-box; height: 70px}
.dropdown-content li a {display: block; font: normal normal 500 16px/20px Noto Sans KR; color: #626262; letter-spacing: 0px; text-align: center; opacity: 1; line-height: 70px;}
.dropdown-content li a:hover {color: #153E06; font: normal normal 500 16px/20px Noto Sans KR; line-height: 70px;}
/* .dropdown-content li:hover { opacity: 1; } */
/* .dropdown:hover .dropdown-content { display: block;} */
.showMenu {display: block;}

/* .dropdown:hover .dropbtn { background-color: #ffffff;} */

.bg{display: none; position: absolute; top: 0; z-index: 1; width: 100%; height: 480px; background: #ffffff;}  
/* .line{width: 100%; position: absolute; top: 80px; height: 1px; background: #eee; } */

.titleMenu {height: 70px; line-height: 70px;}
</style>

