<template>
    <div id="Main">
        <!-- 팝업 -->
        <!-- <div id="popupModal" class="black-bg" v-if="popup == true">
            <div class="white-bg">
                <div class="header">
                    <b-icon icon="x" class="h2 mb-2" @click="close()"></b-icon>
                </div>
                <div class="body">
                    <p class="body_title" style="margin-top: 15px;"> 청약 접수기간 </p>
                    <p class="body_content"> 2022.03.18.(금) 00:00 ~ 2022.03.23.(수) 23:59 </p>

                    <p class="body_title"> 당첨자 발표 </p>
                    <p class="body_content" style="margin-bottom: 10px;"> 2022.03.25.(금) 14:00 (예정) </p>
                    <p class="body_bottom" style="margin-bottom: 30px;"> - 홈페이지 당첨자 조회에서 조회 <br> 문자나 전화로 연락드리지 않으니, 홈페이지상 당첨자 조회를 해주시길 바랍니다.</p>

                    <p class="body_title"> 서류 제출 기간 (당첨자) </p>
                    <p class="body_content"> 2022.03.28.(월) 00:00 ~ 2022.04.01.(금) 23:59 </p>

                    <p class="body_bottom"> 자세한 일정은 공고문에 나와 있으니 확인 부탁드립니다. </p>
                    <p class="body_bottom"> 문의 : 카카오톡 채널 구산주택</p>
                </div>
            </div>
        </div> -->
      <div id="popupModal" class="black-bg" v-if="popup == true">
            <div class="white-bg">
                <div class="header" style="display: flex; justify-content: space-between; align-items: center;">
                    <p class="header_title"> 구산주택 대기희망자 </p>
                    <b-icon icon="x" class="h2 mb-2" @click="close()"></b-icon>
                </div>
                <div class="body">
                    <p class="body_content"> 
                        문의 : 010-4744-8798(문자)<br />
                        상담 가능 시간 : 평일 09:00 ~ 18:00<br /><br />
                        
                        성명/타입/공급유형/대기 가능기간을 <br/> 문자로 보내주시면 대기 등록가능합니다. 
                        <br/>
                        <br/>
                        
                    </p>
                </div>
            </div>
        </div>
        <div class="Main_slide">
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide >
                    <div class="textbox">
                        <!-- <p>도심 속의 여유와 낭만이 가득한 곳</p> -->
                        <!-- <h3> <span>구산주택</span>과 라이프의 <br> 쾌적함을 더하다 </h3> -->
                    </div>
                    <div class="img1">
                    </div>
                    </swiper-slide>
                <swiper-slide >
                    <div class="textbox2">
                        <!-- <p>도심 속의 여유와 낭만이 가득한 곳</p>
                        <h3> <span>구산주택</span>과 라이프의 <br> 쾌적함을 더하다 </h3> -->
                    </div>
                    <div class="img2">
                    </div>
                </swiper-slide> 
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper> 
        </div>
    </div>    
</template>

<script>
// import Vue from "vue";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// import 'swiper/swiper-bundle.css'

export default {
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            facilitesSwiperOptions: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    type: 'fraction'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            popup: true
        };
    },
    created() {},
    methods: {
        close() {
            this.popup = false
        }
    },
    computed: {}
};
</script>

<style lang="scss">
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('../assets/fonts/S Core Dream/s-core-dream.css');

#Main{
    // 팝업창 
	.black-bg{ width: 900px; position: fixed; padding: 20px; z-index: 9999; top:50%; left: 50%; transform: translate(-50%, -50%); overflow: auto; 
		.white-bg{width: 100%; background: white; border-radius: 5px; padding: 20px 25px;  border: 1px solid #9b9b9b; 
			.header{width: 100%; height: 40px;
				.b-icon{float: right; cursor: pointer;}
                .header_title{font: normal normal bold 22px/47px Noto Sans KR; letter-spacing: 0px; color: #242424; opacity: 1; margin-bottom: 10px;}
			}
            .body{ border-top: 1px solid #9b9b9b;
                .body_title{font: normal normal bold 26px/47px Noto Sans KR; letter-spacing: 0px; color: #242424; opacity: 1; margin-bottom: 10px;}
                .body_content{font: normal normal normal 18px/27px Noto Sans KR; letter-spacing: 0.5px; color: #242424; opacity: 1; margin-top: 10px; margin-bottom: 5px;}
                .body_bottom{font: normal normal normal 17px/25px Noto Sans KR; letter-spacing: 0.28px; color: #242424; opacity: 1;}
                .bottom{font: normal normal bold 18px/27px Noto Sans KR; letter-spacing: 0.28px; color: #242424; opacity: 1; margin-bottom: 0;}
                .color{background-color: yellow;}
            }
		}
	}
    .Main_slide{
        .swiper {
            .swiper-slide {
                .img1{background: url("../assets/img/main1.png"),url('../assets/img/사각형 1.png') 0% 0% no-repeat padding-box; width: 100%; height: 1358px; background-size: cover; opacity: 1;}
                // .img1{background: linear-gradient(180deg, rgba(37, 63, 77, 0.282) 0%, #03102eb1 100%), url("../assets/img/main1.png"),url('../assets/img/사각형 1.png') 0% 0% no-repeat padding-box; width: 100%; height: 1358px; background-size: cover; opacity: 1;}
                .img2{background: url("../assets/img/main2.png"),url('../assets/img/사각형 1.png') 0% 0% no-repeat padding-box; width: 100%; height: 1358px; background-size: cover; opacity: 1;}
                // img{width: 100%; height: 1358px;}
                } 
            .textbox{margin: 0 auto; font-weight: normal; text-align: center; color: #FFFFFF; opacity: 1; max-width: 1320px;
                p{letter-spacing: 0px; position: fixed; width: 100%; max-width: 1320px; top: 454px; margin: 0 auto; font: normal normal 24px/36px Noto Sans KR; color: #FFFFFF; opacity: 1;} 
                h3{font: normal normal 50px/65px S-Core Dream; position: fixed; width: 100%; max-width: 1320px; top: 504px; margin: 0 auto; letter-spacing: -2px;  opacity: 1;
                    span{font: normal normal 900 50px/65px S-Core Dream; letter-spacing: -2px; color: #FFFFFF;}
                }
            }
            .textbox2{margin: 0 auto; text-align: right; color: #FFFFFF; opacity: 1; max-width: 1224px;
                p{letter-spacing: 0px; position: fixed; width: 100%; max-width: 1400px; top: 301px; margin: 0 auto; font: normal normal 300 24px/36px Noto Sans KR; color: #2F4B98; opacity: 1;} 
                h3{font: normal normal 500 50px/65px S-Core Dream; position: fixed; width: 100%; max-width: 1398px; top: 354px; margin: 0 auto; letter-spacing: -2px;  opacity: 1; color: #2F4B98;
                    span{font: normal normal 900 50px/65px S-Core Dream; letter-spacing: -2px; color: #2F4B98;}
                }
            }
            .swiper-button-prev{background-image: url("../assets/icon/그룹 51.svg"); background-size:50px; width: 50px; height: 50px; margin-left: 250px;}
            .swiper-button-next{background-image: url("../assets/icon/그룹 71.svg"); background-size:50px; width: 50px; height: 50px; margin-right: 250px;}
            .swiper-button-prev:after{display: none;}
            .swiper-button-next:after{display: none;}
            // h3{font-size: 3em; position: absolute; width: 100%; max-width: 1320px; top: 25%;}
        }
    }
    .wrap{width: 1320px; margin: 0 auto; height: auto; position:relative;
        .overview_wrap{position: relative; 
            .main_title{
                p.sub-title {font: normal normal bold 14px/18px S-Core Dream; color:#2F4B98; margin-top: 84px; margin-bottom: 9px;}
                p{font: normal normal bold 36px/43px S-Core Dream; color: #333333; text-align: left; opacity: 1; width: 100%; margin-bottom: 0; letter-spacing: 0px;}
                .line{margin-top: -20px; }
            }
            .row{margin-bottom: 100px;
                .col-md-7{padding-right: 55px;}
                .textbox{
                    table{width: 100%; border-collapse: collapse; border-spacing: 0; height: 408px;
                        .title{font: normal normal bold 20px/29px Noto Sans KR; letter-spacing: 1px; text-align: left; vertical-align: middle; color: #333333; opacity: 1;} 
                        .txt{padding-left: 27px; text-align: left; vertical-align: middle; font-weight: 300; color: black; font: normal normal normal 18px/27px Noto Sans KR; color: #333333}
                        span.txt-sub{color: #2F4B98; font: normal normal 300 18px/27px Noto Sans KR;}
                        td{overflow: hidden; word-break:normal; font: normal normal normal 14px/20px Noto Sans KR; letter-spacing: 0px; color: #333333;}
                        tr{border-top: 1px solid #E0E0E0;}
                    }
                }
                .imgbox{
                    img{width: 100%;}
                }
            }
            .overview_outline{
                .swiper-container {
                    width: 520px;
                }
                .swiper-pagination {
                    width: 40px;
                    height: 24px;
                    position:absolute;
                    top: 0px;
                    left: 480px;
                }
                .swiper-pagination-bullet-active {
                    background-color:white;
                }
            }
            .overview_around{margin-top: 10%;
                div.around {
                    img{width: 66.5%; margin-left:33.5%; margin-top: -68px;}
                }
            }
            .overview_facilities{margin-top: 10%; 
                .facilities_row {margin-left: -291.5px; margin-top:40px; width: 1900px; height: 700px; background: #F5F5F5 0% 0% no-repeat padding-box;
                    .facilities_area {width: 1700px; height: 700px;  margin-left: 201px; display: flex; position: relative; 
                        .facilities_box {width:1700px; height: 700px; display: flex; margin-left: 50px;
                            .facilities_textbox {width: 747px; height: 100%; margin-top: 96px; margin-left: 40px;
                                .facilities_title {font: normal normal bold 32px/47px Noto Sans KR; color: #242424;}
                                .facilities_content {font: normal normal 300 20px/30px Noto Sans KR; color: #242424;}
                                .facilities_infobox {
                                    .facilities_info_line {display: flex; margin-bottom: 10px;
                                        p.facilities_info_title {border-right: 1px solid #F5F5F5; font: normal normal 400 14px/20px Noto Sans KR; width: 72px; letter-spacing: 2.1px; margin-bottom: 0px;color: #707070;}
                                        p.facilities_info_content {margin-left: 13px; font: normal normal 300 12px/18px Noto Sans KR; line-height: 20px; letter-spacing: -0.24px; margin-bottom: 0px;color: #707070;}
                                    }
                                }
                            }
                            .facilities_imgbox {
                                .facilities_img {width: 820px; height: 700px; margin-left: 40px;}
                            }
                        }
                        .swiper-pagination {position: absolute; width: 52px; color: #242424;; left: 81px; font: normal normal normal 16px/30px Noto Sans KR; z-index: 10; top: 27px; letter-spacing: 0.32px;}
                        .swiper-button-prev {background-image: url("../assets/icon/그룹 51.png"); margin-left: 706px; background-size:27px; margin-top: 15%;}
                        .swiper-button-prev::after {display:none;}
                        .swiper-button-next {background-image: url("../assets/icon/그룹 52.png"); margin-right: 870px; background-size:27px;  margin-top: 15%;}
                        .swiper-button-next::after {display:none;}
                    }
                }
            }
            .overview_map{margin-top: 10%;
                .main_title{margin-bottom: 40px;}
                .map_sub_title{font: normal normal normal 24px/36px Noto Sans KR;}
                .overmap{margin-bottom: 11%;}
            }
        }
    }
}

</style>
