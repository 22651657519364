<template>
    <div class="Overview">
        <PANOLENS v-if="isVrModalShowing" @close-modal="isVrModalShowing = false" :width="1200" :height="600" :imageSrc='vrShowingTarget'></PANOLENS>
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in">
                    <!-- <p class="su_tit">청년주택</p> -->
                </div>
            </div>
        </div>
        <div class="sub">
            <div class="main">
                <div class="content">
                    <div class="content-list">
                        <div class="titlebox" id="overview">
                            <div class="title">
                                <!-- <p class="sub-title">청년주택</p> -->
                                <h2>주택개요</h2>
                            </div>
                            <div class="box">
                                <p>주거면적</p>
                                <div class="textbox summary">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th class="text" style="border-left:none; width: 119px;">임대구분</th><th class="text" style="width: 120px">형 별</th><th class="text" style="width: 120px">세대수</th>
                                                <th class="text" style="width: 120px">전용면적</th><th class="text" style="width: 120px">주거공용</th><th class="text" style="wdith:120px;">공급면적</th>
                                                <th class="text" style="width: 120px">기타공용면적</th><th class="text" style="width: 120px">계약면적</th><th class="text" style="width: 120px">발코니면적</th>
                                                <th class="text" style="width: 120px">대지지분</th><th class="text" style="width: 120px">전용율</th>
                                            </tr>
                                            <tr>
                                                <td class="txttitle" rowspan="3">공공임대</td>
                                                <td class="txt center">17A</td><td class="txt center">15</td><td class="txt">17.60</td>
                                                <td class="txt">12.09</td><td class="txt">29.69</td><td class="txt" style="padding-right: 12px;">6.02</td><td class="txt">35.72</td>
                                                <td class="txt" style="padding-right: 20px;">5.55</td><td class="txt">6.28</td><td class="txt" style="padding-right: 37px;">59.26%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center">33B</td><td class="txt center">6</td><td class="txt">33.95</td>
                                                <td class="txt">21.37</td><td class="txt">55.32</td><td class="txt" style="padding-right: 12px;">11.61</td><td class="txt">66.94</td>
                                                <td class="txt" style="padding-right: 20px;">9.42</td><td class="txt">11.77</td><td class="txt" style="padding-right: 37px;">61.36%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center">소계</td><td class="txt center">21</td><td class="txt">467.70</td>
                                                <td class="txt">309.75</td><td class="txt">777.45</td><td class="txt" style="padding-right: 12px;">160.02</td><td class="txt">937.47</td>
                                                <td class="txt" style="padding-right: 20px;"></td><td class="txt">164.90</td><td class="txt" style="padding-right: 37px;">60.16%</td>
                                            </tr>
                                            <tr style="border-top: 2px solid #707070;">
                                                <td class="txttitle" rowspan="5">민간임대</td>
                                                <td class="txt center">17A</td><td class="txt center">115</td><td class="txt">17.60</td>
                                                <td class="txt">12.09</td><td class="txt">29.69</td><td class="txt" style="padding-right: 12px;">6.02</td><td class="txt">35.72</td>
                                                <td class="txt" style="padding-right: 20px;">5.55</td><td class="txt">6.28</td><td class="txt" style="padding-right: 37px;">59.26%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center">17B</td><td class="txt center">28</td><td class="txt">17.70</td>
                                                <td class="txt">12.20</td><td class="txt">29.90</td><td class="txt" style="padding-right: 12px;">6.06</td><td class="txt">35.96</td>
                                                <td class="txt" style="padding-right: 20px;">11.57</td><td class="txt">6.32</td><td class="txt" style="padding-right: 37px;">59.18%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center">33A</td><td class="txt center">16</td><td class="txt">33.87</td>
                                                <td class="txt">21.44</td><td class="txt">55.31</td><td class="txt" style="padding-right: 12px;">11.59</td><td class="txt">66.91</td>
                                                <td class="txt" style="padding-right: 20px;">9.42</td><td class="txt">11.77</td><td class="txt" style="padding-right: 37px;">61.36%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center">33B</td><td class="txt center">58</td><td class="txt">33.95</td>
                                                <td class="txt">21.37</td><td class="txt">55.32</td><td class="txt" style="padding-right: 12px;">11.61</td><td class="txt">66.94</td>
                                                <td class="txt" style="padding-right: 20px;">9.42</td><td class="txt">11.77</td><td class="txt" style="padding-right: 37px;">61.23%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center">소계</td><td class="txt center">217</td><td class="txt">5,030.62</td>
                                                <td class="txt">3,316.21</td><td class="txt">8,346.83</td><td class="txt" style="padding-right: 12px;">1,721.59</td><td class="txt">10,068.42</td>
                                                <td class="txt" style="padding-right: 20px;"></td><td class="txt">1,770.98</td><td class="txt" style="padding-right: 37px;">60.26%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt0 center" style="border-left:none; font: normal normal 500 16px/24px Noto Sans KR; letter-spacing: 1.92px; color: #333333;" colspan="2">합계</td>
                                                <td class="txt0 center">238</td><td class="txt0">5,498.32</td><td class="txt0">3,625.96</td>
                                                <td class="txt0">9,124.28</td><td class="txt0" style="padding-right: 12px;">1,881.62</td><td class="txt0">11,005.89</td><td class="txt0"></td>
                                                <td class="txt0">1,935.87</td><td class="txt0" style="padding-right: 37px;">60.26%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="small">
                                    <p>
                                        ※ 공급면적은 전용면적과 공용면적(주거)을 합한 면적입니다. <br>
                                        ※ 기타공용면적은 부대복리시설면적을 전용면적비율로 나눈 면적입니다.<br>
                                        ※ 계약면적은 전용, 공용, 기타공용면적을 합친 면적입니다.<br>
                                        ※ 전용면적을 제외한 상기 면적은 준공시 일부 변경될 수 있습니다.
                                    </p>
                                </div>  
                            </div>
                            <div class="box">
                                <div style="width: 100%; display: flex">
                                    <p style="display: left;">임대료</p>
                                    <p style="display: left; margin-left: 1110px; margin-bottom: 0px; margin-top:20px; font: normal normal normal 18px/27px Noto Sans KR;">(금액단위: 만원)</p>
                                </div>
                                <div class="textbox">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th class="text" style="border-left:none;" colspan="2">임대구분</th><th class="text" style="border-left: 1px solid #707070; border-right: 1px solid #707070;" colspan="7">특별공급</th><th class="text" colspan="7">일반공급</th>
                                            </tr>
                                            <tr>
                                                <td class="txttitle center" style="border-left:none; width: 60px" rowspan="2">세대<br />타입</td><td class="txttitle center" rowspan="2" style="width: 60px">총세<br />대수</td>
                                                <td class="txttitle center" rowspan="2" style="width: 50.5px; border-left: 1px solid #707070; border-right: 1px solid #707070;">세<br />대<br />수</td><td class="txttitle center" colspan="2">보증금 30%</td><td class="txttitle center" colspan="2">보증금 35%</td><td class="txttitle center" colspan="2">보증금 40%</td>
                                                <td class="txttitle center" rowspan="2" style="width: 50.5px; border-left: 1px solid #707070; border-right: 1px solid #707070;">세<br />대<br />수</td><td class="txttitle center" colspan="2" style="height: 40px">보증금 30%</td><td class="txttitle center" colspan="2">보증금 35%</td><td class="txttitle center" colspan="2">보증금 40%</td>
                                            </tr>
                                            <tr>
                                                <td class="txt center" style="width: 92px">임대보증금</td><td class="txt center"  style="width: 92px">월임대료</td><td class="txt center" style="width: 92px">임대보증금</td><td class="txt center" style="width: 92px">월임대료</td>
                                                <td class="txt center" style="width: 92px">임대보증금</td><td class="txt center" style="width: 92px">월임대료</td><td class="txt center" style="width: 92px">임대보증금</td><td class="txt center" style="width: 92px">월임대료</td>
                                                <td class="txt center" style="width: 92px">임대보증금</td><td class="txt center" style="width: 92px">월임대료</td><td class="txt center" style="width: 92px">임대보증금</td><td class="txt center" style="width: 92px">월임대료</td>
                                            </tr>
                                            <tr>
                                                <td class="txt_content" style="border-left:none;">17A</td><td class="txt_content">115</td><td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">31</td><td class="txt_content">3,600</td>
                                                <td class="txt_content">35</td><td class="txt_content">4,200</td><td class="txt_content">32</td><td class="txt_content">4,800</td><td class="txt_content">29</td>
                                                <td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">84</td><td class="txt_content">4,000</td><td class="txt_content">42</td><td class="txt_content">4,700</td><td class="txt_content">39</td>
                                                <td class="txt_content">5,300</td><td class="txt_content">36</td>
                                            </tr>
                                            <tr>
                                                <td class="txt_content" style="border-left:none;">17B</td><td class="txt_content">28</td><td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">-</td><td class="txt_content">-</td>
                                                <td class="txt_content">-</td><td class="txt_content">-</td><td class="txt_content">-</td><td class="txt_content">-</td><td class="txt_content">-</td>
                                                <td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">28</td><td class="txt_content">4,000</td><td class="txt_content">42</td><td class="txt_content">4,700</td><td class="txt_content">39</td>
                                                <td class="txt_content">5,300</td><td class="txt_content">36</td>
                                            </tr>
                                            <tr>
                                                <td class="txt_content" style="border-left:none;">33A</td><td class="txt_content">16</td><td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">7</td><td class="txt_content">6,200</td>
                                                <td class="txt_content">57</td><td class="txt_content">7,100</td><td class="txt_content">53</td><td class="txt_content">8,200</td><td class="txt_content">48</td>
                                                <td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">9</td><td class="txt_content">6,900</td><td class="txt_content">67</td><td class="txt_content">8,000</td><td class="txt_content">62</td>
                                                <td class="txt_content">9,100</td><td class="txt_content">58</td>
                                            </tr>
                                            <tr>
                                                <td class="txt_content" style="border-left:none;">33B</td><td class="txt_content">58</td><td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">6</td><td class="txt_content">6,200</td>
                                                <td class="txt_content">57</td><td class="txt_content">7,100</td><td class="txt_content">53</td><td class="txt_content">8,200</td><td class="txt_content">48</td>
                                                <td class="txt center content" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">52</td><td class="txt_content">6,900</td><td class="txt_content">67</td><td class="txt_content">8,000</td><td class="txt_content">62</td>
                                                <td class="txt_content">9,100</td><td class="txt_content">58</td>
                                            </tr>
                                            <tr>
                                                <td class="txt0 center" style="border-left:none;">합계</td><td class="txt0 center">217</td><td class="txt0 center" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">44</td>
                                                <td class="txt0 center" colspan="6">전체 세대수의 20.28%</td><td class="txt0 center" style="border-left: 1px solid #707070; border-right: 1px solid #707070;">173</td><td class="txt0 center" colspan="6">전체 세대수의 79.72%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="small" style="margin-bottom: 140px;">
                                    <p>
                                        ※ 임대보증금 및 월임대료는 임대사업자가 공급하는 주택의 전용면적을 기준으로 책정된 것이며, 공용면적 등을 사용하지 않는 등의 사유로 변경할 수 없습니다. <br>
                                        ※ 위 임대료는 서울시와 협약한 최초의 임대료이고, 주택임대사업자는 민간임대주택에 관한 특별법 제44조 제2항에 의거하여 연간 5퍼센트 범위 내에서 주거비 물가지수, 인근 지역의 임대료 변동률 등을 <br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;고려하여 조정 가능합니다. <br>
                                        ※ 임대보증금을 월임대료로 전환하거나, 월임대료를 임대보증금으로 전환하는 경우에는 임대보증금비율을 30%, 35%, 40% 단위로 전환이 가능하며, 입주 전에 선택한 임대조건(임대보증금 비율)은 계약 <br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;갱신 전까지 변경 불가합니다. <br>
                                        ※ 33A, 33B타입은 신혼부부를 대상으로 우선 공급되며 미달시 대학생·청년도 계약이 가능합니다.
                                    </p>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="sub_content">
                        <div class="titlebox" id="household">
                            <div class="title">
                                <!-- <p class="sub-title">청년주택</p> -->
                                <h2>세대정보</h2>
                            </div>
                            <div class="main">
                                <input type="radio" id="tab-1" name="show" checked>
                                <input type="radio" id="tab-2" name="show">
                                <input type="radio" id="tab-3" name="show">
                                <input type="radio" id="tab-4" name="show">
                                <div class="tab">
                                    <label for="tab-1">17A</label>
                                    <label for="tab-2">17B</label>
                                    <label for="tab-3">33A</label>
                                    <label for="tab-4">33B</label>
                                </div>
                                <div class="tab_content">
                                    <!-- 17A형 -->
                                    <div class="content-dis">
                                        <swiper class="swiper" :options="swiperOption">
                                            <swiper-slide><img style="transform: scale(0.6);" src="@/assets/img/overview/17a 단위세대 Iso.png"></swiper-slide> 
                                            <swiper-slide><img src="@/assets/img/overview/17A 단위세대 평면매핑.png" style="margin-top:-20%;"></swiper-slide>
                                            <div class="swiper-pagination" slot="pagination"></div>
                                            <div class="swiper-button-prev" slot="button-prev"></div>
                                            <div class="swiper-button-next" slot="button-next"></div>
                                        </swiper>
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-md-3" style="margin-right: 0;">
                                                    <h1 class="name">17A</h1>
                                                    <button type="button" @click="showVrModal('17A.png')"><p>VR <span class="look">보기</span></p></button>
                                                    <!-- <div class="contentbox">
                                                        <span class="left">임대보증금 :</span><p class="right">4,700,000원</p>
                                                        <span class="left">월 임대료 :</span><p class="right">100,000,000원</p>
                                                    </div> -->
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="contentbody">
                                                        <div class="text">
                                                            <span class="left">주거전용</span>
                                                            <p class="right">17.60㎡</p>
                                                            <span class="left">주거공용</span>
                                                            <p class="right">12.09㎡</p>
                                                            <span class="left">기타공용</span>
                                                            <p class="right">&nbsp;&nbsp;6.02㎡</p>
                                                            <span class="left">계약면적</span>
                                                            <p class="right">35.72㎡</p>
                                                        </div>
                                                    </div>
                                                    <div class="contentbottom">
                                                        <div class="option">
                                                            <span>세탁기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>에어컨</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>냉장고</span> 
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">  
                                                        </div>
                                                        <div class="option">
                                                            <span>인덕션</span>
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">   
                                                        </div>
                                                        <div class="option">
                                                            <span>빌트인가구</span>  
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png"> 
                                                        </div>
                                                        <div class="option">
                                                            <span>전열교환기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 17B형 -->
                                    <div class="content-dis">
                                        <swiper class="swiper" :options="swiperOption">
                                            <swiper-slide><img style="transform: scale(0.6);" src="@/assets/img/overview/17b 단위세대 Iso.png"></swiper-slide> 
                                            <swiper-slide><img src="@/assets/img/overview/17B 단위세대 평면매핑.png"  style="margin-top:-15%; margin-left: 5%; width: 90%;"></swiper-slide>
                                            <div class="swiper-pagination" slot="pagination"></div>
                                            <div class="swiper-button-prev" slot="button-prev"></div>
                                            <div class="swiper-button-next" slot="button-next"></div>
                                        </swiper>
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-md-3" style="margin-right: 0;">
                                                    <h1 class="name">17B</h1>
                                                    <button type="button" @click="showVrModal('17B.png')"><p>VR <span class="look">보기</span></p></button>
                                                    <!-- <div class="contentbox">
                                                        <span class="left">임대보증금 :</span><p class="right">4,700,000원</p>
                                                        <span class="left">월 임대료 :</span><p class="right">100,000,000원</p>
                                                    </div> -->
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="contentbody">
                                                        <div class="text">
                                                            <span class="left">주거전용</span>
                                                            <p class="right">17.70㎡</p>
                                                            <span class="left">주거공용</span>
                                                            <p class="right">12.20㎡</p>
                                                            <span class="left">기타공용</span>
                                                            <p class="right">&nbsp;&nbsp;6.06㎡</p>
                                                            <span class="left">계약면적</span>
                                                            <p class="right">35.96㎡</p>
                                                        </div>
                                                    </div>
                                                    <div class="contentbottom">
                                                        <div class="option">
                                                            <span>세탁기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>에어컨</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>냉장고</span> 
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">  
                                                        </div>
                                                        <div class="option">
                                                            <span>인덕션</span>
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">   
                                                        </div>
                                                        <div class="option">
                                                            <span>빌트인가구</span>  
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png"> 
                                                        </div>
                                                        <div class="option">
                                                            <span>전열교환기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 33A형 -->
                                    <div class="content-dis">
                                        <swiper class="swiper" :options="swiperOption">
                                            <swiper-slide><img style="transform: scale(0.6);" src="@/assets/img/overview/33a 단위세대 Iso.png"></swiper-slide> 
                                            <swiper-slide><img src="@/assets/img/overview/33A 단위세대 평면매핑.png"  style="margin-top:-20%;"></swiper-slide>
                                            <div class="swiper-pagination" slot="pagination"></div>
                                            <div class="swiper-button-prev" slot="button-prev"></div>
                                            <div class="swiper-button-next" slot="button-next"></div>
                                        </swiper>
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-md-3" style="margin-right: 0;">
                                                    <h1 class="name">33A</h1>
                                                    <button type="button" @click="showVrModal('33A.png')"><p>VR <span class="look">보기</span></p></button>
                                                    <!-- <div class="contentbox">
                                                        <span class="left">임대보증금 :</span><p class="right">4,700,000원</p>
                                                        <span class="left">월 임대료 :</span><p class="right">100,000,000원</p>
                                                    </div> -->
                                                </div>
                                                <div class="col-md-9">
                                                       <div class="contentbody">
                                                        <div class="text">
                                                            <span class="left">주거전용</span>
                                                            <p class="right">33.87㎡</p>
                                                            <span class="left">주거공용</span>
                                                            <p class="right">21.44㎡</p>
                                                            <span class="left">기타공용</span>
                                                            <p class="right">11.59㎡</p>
                                                            <span class="left">계약면적</span>
                                                            <p class="right">66.91㎡</p>
                                                        </div>
                                                    </div>
                                                    <div class="contentbottom">
                                                        <div class="option">
                                                            <span>세탁기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>에어컨</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>냉장고</span> 
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">  
                                                        </div>
                                                        <div class="option">
                                                            <span>인덕션</span>
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">   
                                                        </div>
                                                        <div class="option">
                                                            <span>빌트인가구</span>  
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png"> 
                                                        </div>
                                                        <div class="option">
                                                            <span>전열교환기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 33B형 -->
                                    <div class="content-dis">
                                        <swiper class="swiper" :options="swiperOption">
                                            <swiper-slide><img style="transform: scale(0.6);" src="@/assets/img/overview/33b 단위세대 Iso.png"></swiper-slide> 
                                            <swiper-slide><img src="@/assets/img/overview/33B 단위세대 평면매핑.png" style="margin-top:-20%;"></swiper-slide>
                                            <div class="swiper-pagination" slot="pagination"></div>
                                            <div class="swiper-button-prev" slot="button-prev"></div>
                                            <div class="swiper-button-next" slot="button-next"></div>
                                        </swiper>
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-md-3" style="margin-right: 0;">
                                                    <h1 class="name">33B</h1>
                                                    <button type="button" @click="showVrModal('33B.png')"><p>VR <span class="look">보기</span></p></button>
                                                    <!-- <div class="contentbox">
                                                        <span class="left">임대보증금 :</span><p class="right">4,700,000원</p>
                                                        <span class="left">월 임대료 :</span><p class="right">100,000,000원</p>
                                                    </div> -->
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="contentbody">
                                                        <div class="text">
                                                            <span class="left">주거전용</span>
                                                            <p class="right">33.95㎡</p>
                                                            <span class="left">주거공용</span>
                                                            <p class="right">21.37㎡</p>
                                                            <span class="left">기타공용</span>
                                                            <p class="right">11.61㎡</p>
                                                            <span class="left">계약면적</span>
                                                            <p class="right">66.94㎡</p>
                                                        </div>
                                                    </div>
                                                    <div class="contentbottom">
                                                        <div class="option">
                                                            <span>세탁기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>에어컨</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                        <div class="option">
                                                            <span>냉장고</span> 
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">  
                                                        </div>
                                                        <div class="option">
                                                            <span>인덕션</span>
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">   
                                                        </div>
                                                        <div class="option">
                                                            <span>빌트인가구</span>  
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png"> 
                                                        </div>
                                                        <div class="option">
                                                            <span>전열교환기</span>   
                                                            <img src="@/assets/icon/free-icon-checkbox-1287087.png">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sub_content">
                            <div class="titlebox" id="household">
                                <div class="title">
                                    <!-- <p class="sub-title">청년주택</p> -->
                                    <h2>동호수 배치도</h2>
                                </div>
                                <div class="img">
                                    <img id="layout_img" src="@/assets/img/동호수배치도.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Vue from "vue";
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import PANOLENS from '../components/PANOLENS_Viewer.vue'
// import Swiper from 'swiper/swiper';
export default {
    components: {
        swiper,
        swiperSlide,
        PANOLENS
    },
    data() {
        return {
            isVrModalShowing: false,
            vrShowingTarget: "33B.png",
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            }
        };
    },
    watch: {
        // contract_type : {
        //     handler(type) {
        //         this.changeView(type)
        //     },
        //     deep: true
        // }
    },
    mounted() {
        // this.changeView(this.contract_type);
    },
    created() {},
    methods: {
        showVrModal(target) {
            this.vrShowingTarget = target;
            this.isVrModalShowing = true;
        },
        // changeView(type) {
        //     if(type == '17A') { 
        //         document.getElementById('tab-1').checked = true;
        //     } else if(type == '17B') {
        //         document.getElementById('tab-2').checked = true;
        //     } else if(type == '33A') {
        //         document.getElementById('tab-3').checked = true;
        //     } else if(type == '33B') {
        //         document.getElementById('tab-4').checked = true;
        //     }
        // },
    },
    computed: {}
};
</script>

<style lang="scss">

.Overview {position: relative; width: 100%;
    .inner{
        .sub_visual.sub1bg{
            .in{width:100%; text-align: center; padding-top: 110px;} 
        }
    }
    .sub{
        .main{margin: 0 auto; max-width: 1330px; margin-bottom: 188px;}
        .content {min-height: 1000px;}
        .content > div{ line-height: 1.5; font-size: 17px;}

        .titlebox{
            .title{
                h2{font: normal normal bold 36px/43px S-Core Dream; letter-spacing: 2.16px; color: #153E06; opacity: 1; margin-bottom: 40px;}
            }
            .box{
                P{text-align: left; font: normal normal 30px/36px S-Core Dream; letter-spacing: 0px; color: #333333; opacity: 1;}
                .textbox.summary {
                    table{width: 1320px; border-collapse: collapse; border-spacing: 0;
                        .text{height: 34px; font:normal normal bold 18px/27px Noto Sans KR; letter-spacing: 1.92px; color: #333333; opacity: 1;}
                        .txttitle{border-left:none; text-align:center; padding-right:0px; font: normal normal 500 18px/27px Noto Sans KR; letter-spacing: 1.92px; color: #333333; opacity: 1;}
                        .txt{height: 34px; text-align: right; padding-right:28px; font: normal normal normal 18px/27px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; }
                        .txt0{height: 34px; font: normal normal 500 20px/29px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1;  border-top: 2px solid #707070;  border-bottom: 2px solid #707070;}
                        th{border-top: 2px solid #707070; border-bottom: 2px solid #707070; opacity: 1; text-align: center;}
                        td.center{text-align: center; padding-right:0px; }
                        td{text-align: right; padding-right: 28px; font: normal normal normal 15px/22px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; border-bottom: 1px solid #707070;}
                    }
                }
                .textbox{
                    table{width: 1320px; border-collapse: collapse; border-spacing: 0;
                        .text{height: 40px; font: normal normal bold 18px/27px Noto Sans KR;letter-spacing: 1.92px; color: #333333; opacity: 1; text-align: center;}
                        .txt_content{height: 34px; font: normal normal normal 18px/27px Noto Sans KR;}
                        .txt0{height: 34px; font: normal normal 16px/24px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1;  border-top: 2px solid #707070;  border-bottom: 2px solid #707070;}
                        .txt0.center{font: normal normal medium 20px/29px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; }
                        th{border-top: 2px solid #707070; border-bottom: 2px solid #707070; opacity: 1; text-align: center; color: #333333;}
                        td.center{text-align: center; padding-right:0px; letter-spacing: 1.2px; color: #333333; font: normal normal 500 18px/27px Noto Sans KR;}
                        td.center.content{font: normal normal normal 16px/24px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1;}
                        td{text-align: right; padding-right:15px; font: normal normal normal 15px/22px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; border-bottom: 1px solid #707070;}
                    }
                }
                .small{ margin-top: 15px;
                    p{text-align: left; font: normal normal normal 16px/30px Noto Sans KR; letter-spacing: -0.32px; color: #626262; opacity: 1; margin-bottom: 50px;}
                }
            }
        }
        .sub_content{max-width: 1320px;
            .main{
                input[type=radio] { display: none; }
                #tab-1:checked ~ .tab label:nth-child(1),
                #tab-2:checked ~ .tab label:nth-child(2),
                #tab-3:checked ~ .tab label:nth-child(3),
                #tab-4:checked ~ .tab label:nth-child(4) {background: url("../assets/icon/사각형 206.png") 0% 0% no-repeat padding-box; color: #FFFFFF;}
                // .tab_content > div { display: none;}

                // #tab-1:checked ~ .tab_content div:nth-child(2),
                // #tab-2:checked ~ .tab_content div:nth-child(3),
                // #tab-3:checked ~ .tab_content div:nth-child(4),
                // #tab-4:checked ~ .tab_content div:nth-child(5) {display: block;}
                div.content-dis {
                    display: none;
                }
                #tab-1:checked ~ .tab_content .content-dis:nth-child(1),
                #tab-2:checked ~ .tab_content .content-dis:nth-child(2),
                #tab-3:checked ~ .tab_content .content-dis:nth-child(3),
                #tab-4:checked ~ .tab_content .content-dis:nth-child(4) {display: block;}
                // .main { margin: 0 auto; max-width: 1280px;}
                .tab { overflow: hidden; margin-bottom: 25px;}
                .tab label {font: normal normal bold 40px/59px Noto Sans KR; cursor: pointer; float: left; width: 330px; height: 72px; text-align: center; border-radius: 11px; letter-spacing: 0px; padding: 5px 0;}
                
                .swiper{border: 1px solid #707070; width: 1320px; height: 600px; margin: 0; opacity: 1; padding: 10%;
                    img{width: 100%; margin-top: -27%;}
                    .swiper-button-prev{background-image: url("../assets/icon/그룹 51.svg"); background-size:50px; width: 50px; height: 50px; margin-left: 35px;}
                    .swiper-button-next{background-image: url("../assets/icon/그룹 71.svg"); background-size:50px; width: 50px; height: 50px; margin-right: 35px;}
                    .swiper-button-prev:after{display: none;}
                    .swiper-button-next:after{display: none;}
                }
                .swiper-wrapper{transform: none}
            
                li{width: 50%;}
                .inner{width: 100%; margin-top: 25px;
                    .row{
                        .col-md-3{margin-right: 0; border-right:1px solid #C6C6C6; width: 23%;
                            .name{text-align: left; font: normal normal 900 120px/143px S-Core Dream; letter-spacing: 0px; color: #333333; opacity: 1; margin-left: 16px;}
                            button{margin-top: 12px; background: #153E06 0% 0% no-repeat padding-box; border-radius: 12px; opacity: 1; height: 56px; width: 225px; border: 0; vertical-align: middle; margin-left: 25px;
                                p{text-align: center; font: normal normal 900 32px/47px Noto Sans KR; letter-spacing: 0px; color: #FFFFFF; margin-bottom: 0px;} 
                                .look{font: normal normal 32px/47px Noto Sans KR; letter-spacing: 0px; color: #FFFFFF;} 
                            }
                            // .contentbox{width: 100%; margin-top: 50px;
                            //     .left{padding-right: 25px; text-align: left; font: normal normal bold 16px/24px Noto Sans KR; letter-spacing: 1.92px; color: #2F4B98; float: left; opacity: 1;}
                            //     .right{font: normal normal 500 16px/24px Noto Sans KR; letter-spacing: 0px; color: #2F4B98; opacity: 1; margin-bottom: 20px;}
                            // }
                        }
                        .col-md-9{float: left; padding-left: 80px; margin-top: 10px;
                            .contentbody{width: 100%; height: 160px;
                                .text{width: 50%; padding-right: 10%;
                                    .left{padding-right: 20px; text-align: left; font: normal normal bold 24px/36px Noto Sans KR; letter-spacing: 2.4px; color: #333333; float: left;}
                                    .right{font: normal normal 500 18px/27px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; margin-bottom: 15px;}
                                    
                                }
                            }
                            .contentbottom{ width: 100%; margin-top: 15px;
                                .option{width: 100%;
                                    span{float: left; text-align: left; font: normal normal 16px/20px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1;}
                                    img{width: 16px; height: 16px; float: left; margin-top: 4px; margin-left: 5px; margin-right: 30px;}
                                }
                            }    
                        }     
                    }           
                }
            }
        }
        .img{width: 1320px; height: 1725px; background: #FFFFFF 0% 0% no-repeat padding-box; }
        #layout_img{width: 1319px; height: 1725px;}
    }
    // p.sub-title {font: normal normal bold 14px/18px S-Core Dream; color:#2F4B98; margin-top: 84px; margin-bottom: 9px;}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {top: 10px; bottom: 0; height: 40px;}
}
</style>