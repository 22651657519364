import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";

// Vue.use(Swiper, SwiperSlide )
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.prototype.$apiUrl = "http://duckyoung2016.com:8080";


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
