<template>
    <div id="Store">
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in">
                    <!-- <p class="su_tit">상가정보</p> -->
                </div>
            </div>
        </div>
        <div class="sub_content">
            <div class="main">
                <input type="radio" id="tab-1" @click="changeType('상가')" name="show" checked/>
                <input type="radio" id="tab-2" @click="changeType('편의시설')" name="show"/>
                <div class="tab">
                    <label for="tab-1">상가</label>
                    <label for="tab-2">편의시설</label>
                </div>
                <div class="content">
                    <!-- 상가 -->
                    <div class="content-dis">
                        <div class="content_sub" name="store">
                            <div class="imgbox">
                                <div class="imgbox_sub">
                                    <h1>1층<span style="font: normal normal normal 28px/41px Noto Sans KR;">(상가)</span></h1>
                                    <img src="@/assets/img/store/1F_220303@4x.png">
                                </div>
                            </div>
                            <div class="textbox">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th class="text" style="border-left:none; width:8%;">호실</th><th class="text" colspan="2"  style="width:25%;">전용면적</th><th class="text" style="width:13%;">공용면적</th>
                                            <th class="text" style="width:13%;">공급면적</th><th class="text" style="width:13%;">주차장</th><th class="text" style="width:13%;">주차장</th><th class="text" style="width:13%;">대지지분</th>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">101</td><td class="txt" style="text-align: right; padding-right: 10px;">64.8000㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(19.60평)</td><td class="txt">6.6564㎡</td>
                                            <td class="txt">71.4564㎡</td><td class="txt">8.7012㎡</td><td class="txt">80.1576㎡</td><td class="txt">13.9603㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">102</td><td class="txt" style="text-align: right; padding-right: 10px;">63.2000㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(19.12평)</td><td class="txt">6.4921㎡</td>
                                            <td class="txt">69.6921㎡</td><td class="txt">8.4863㎡</td><td class="txt">78.1784㎡</td><td class="txt">13.6156㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">103</td><td class="txt" style="text-align: right; padding-right: 10px;">65.4000㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(19.78평)</td><td class="txt">6.7181㎡</td>
                                            <td class="txt">72.1181㎡</td><td class="txt">8.7818㎡</td><td class="txt">80.8998㎡</td><td class="txt">14.0896㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">104</td><td class="txt" style="text-align: right; padding-right: 10px;">83.8923㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(25.38평)</td><td class="txt">8.6177㎡</td>
                                            <td class="txt">92.5099㎡</td><td class="txt">11.2649㎡</td><td class="txt">103.7748㎡</td><td class="txt">18.0735㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">105</td><td class="txt" style="text-align: right; padding-right: 10px;">85.1396㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(25.75평)</td><td class="txt">8.7458㎡</td>
                                            <td class="txt">93.8854㎡</td><td class="txt">11.4323㎡</td><td class="txt">105.3177㎡</td><td class="txt">18.3422㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">106</td><td class="txt" style="text-align: right; padding-right: 10px;">90.3886㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(27.34평)</td><td class="txt">9.2850㎡</td>
                                            <td class="txt">99.6736㎡</td><td class="txt">12.1372㎡</td><td class="txt">111.8107㎡</td><td class="txt">19.4730㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt" style="border-left:none; text-align: center;">107</td><td class="txt" style="text-align: right; padding-right: 10px;">90.6805㎡</td><td class="txt" style="width:13%; text-align:left; padding-left:25px;">(27.43평)</td><td class="txt">9.3150㎡</td>
                                            <td class="txt">99.9954㎡</td><td class="txt">12.1764㎡</td><td class="txt">112.1718㎡</td><td class="txt">19.5359㎡</td>
                                        </tr>
                                        <tr>
                                            <td class="txt0" style="border-left:none; text-align: center;">소계</td><td class="txt0" style="text-align: right; padding-right: 10px;">543.5009㎡</td><td class="txt0" style="width:13%; text-align:left; padding-left:16px;">(164.41평)</td><td class="txt0">55.8300㎡</td>
                                            <td class="txt0">599.3309㎡</td><td class="txt0">72.9800㎡</td><td class="txt0">672.3109㎡</td><td class="txt0">117.0900㎡</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- 편의시설 -->
                    <div class="content-dis">
                        <div class="content_sub" name="store">
                            <div class="imgbox">
                                <div class="imgbox_sub">
                                    <h1>2층<span style="font: normal normal normal 28px/41px Noto Sans KR;">(편의시설)</span></h1>
                                    <img src="@/assets/img/store/2F_220303@4x.png">
                                </div>
                            </div>
                            <div class="overview_facilities" :class="{show_sub_item: !isFirstPage}">
                                <div class="overtit">
                                    <div class="facilities_row">
                                        <div class="facilities_area">
                                            <swiper class="facilities_swiper" :options="facilitesSwiperOptions">
                                                <swiper-slide>
                                                    <div class="facilities_box">
                                                        <div class="facilities_textbox">
                                                            <!-- <p class="facilities_sub_title">편의시설</p> -->
                                                            <p class="facilities_title">카셰어링</p>
                                                            <p class="facilities_content">
                                                                구산주택 입주민을 위한 쉽고 빠른 차량공유 서비스,<br /> 
                                                                차량이 필요할때 아파트 주차장에서 누구나 언제든<br /> 
                                                                합리적인 비용으로 이용할 수 있습니다.
                                                            </p>
                                                            <div class="facilities_infobox" style="margin-top: 40px;">
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용시간</p>
                                                                    <p class="facilities_info_content">24시간 (연중무휴)</p>
                                                                </div>
                                                                <div class="facilities_info_line" style="display:flex">
                                                                    <p class="facilities_info_title">이용대상</p>
                                                                    <p class="facilities_info_content">누구나</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용방법</p>
                                                                    <p class="facilities_info_content">쏘카, 그린카, 피플카 앱을 통하여 조회 후 사용</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">위치안내</p>
                                                                    <p class="facilities_info_content">구산주택 주차장(기계식주차장 내)</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용요금</p>
                                                                    <p class="facilities_info_content">앱을 통해 차량 예약시 확인</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="facilities_imgbox ">
                                                            <img class="facilities_img" src="@/assets/img/facilities/카셰어링.png" style="margin-top:-10px;">
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                                <swiper-slide>
                                                    <div class="facilities_box">
                                                        <div class="facilities_textbox">
                                                            <!-- <p class="facilities_sub_title">편의시설</p> -->
                                                            <p class="facilities_title">코인세탁실</p>
                                                            <p class="facilities_content" style="letter-spacing: -0.2px;">
                                                                    집안에서 세탁하기 힘든 침대, 커튼같은 대형 빨래나, 운동화 등을 편리하게 세탁할 수 있고, <br /> 
                                                                    집안에서 세탁한 빨래를 빨리 건조하고 싶다면 구산주택 2층에 위치한 코인세탁실로 오세요. <br /> 
                                                                    여러분의 젖은 세탁물이 건조를 기다립니다.
                                                                </p>
                                                            <div class="facilities_infobox" style="margin-top: 40px;">
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용시간</p>
                                                                    <p class="facilities_info_content">24시간 (연중무휴)</p>
                                                                </div>
                                                                <div class="facilities_info_line" style="display:flex">
                                                                    <p class="facilities_info_title">이용대상</p>
                                                                    <p class="facilities_info_content">누구나</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용방법</p>
                                                                    <p class="facilities_info_content">키오스크를 이용하여 세탁이용</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">위치안내</p>
                                                                    <p class="facilities_info_content">구산주택2층(생활지원센터 옆)</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용요금</p>
                                                                    <p class="facilities_info_content">추후공지</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="facilities_imgbox ">
                                                            <img class="facilities_img" src="@/assets/img/facilities/코인세탁실 (출처 Credit - Pedro BeraldoCourtesy of Celsious).png">
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                                <swiper-slide>
                                                    <div class="facilities_box">
                                                        <div class="facilities_textbox">
                                                            <!-- <p class="facilities_sub_title">편의시설</p> -->
                                                            <p class="facilities_title">구산라운지</p>
                                                            <p class="facilities_content">
                                                                구산주택에 사는 이웃을 만나세요. 우연한 만남, 이웃과 소통, 청년을 위한 이벤트, 커피도 있구요,<br>
                                                                회의테이블도 있습니다. 이 장소는 다양한 구산주택 주민들의 의견을 듣고 함께 만들어갈게요.
                                                            </p>
                                                            <div class="facilities_infobox" style="margin-top: 40px;">
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용시간</p>
                                                                    <p class="facilities_info_content">24시간 (연중무휴)</p>
                                                                </div>
                                                                <div class="facilities_info_line" style="display:flex">
                                                                    <p class="facilities_info_title">이용대상</p>
                                                                    <p class="facilities_info_content">누구나</p>
                                                                </div>
                                                                <!-- <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용방법</p>
                                                                    <p class="facilities_info_content">키오스크를 이용하여 구산라운지이용</p>
                                                                </div> -->
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">위치안내</p>
                                                                    <p class="facilities_info_content">구산주택2층</p>
                                                                </div>
                                                                <!-- <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용요금</p>
                                                                    <p class="facilities_info_content">추후공지</p>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="facilities_imgbox ">
                                                            <img class="facilities_img" src="@/assets/img/facilities/구산라운지.jpg">
                                                        </div>
                                                    </div>
                                                </swiper-slide>
                                                <swiper-slide>
                                                    <div class="facilities_box">
                                                        <div class="facilities_textbox">
                                                            <!-- <p class="facilities_sub_title">편의시설</p> -->
                                                            <p class="facilities_title">공익활동플랫폼</p>
                                                            <p class="facilities_content">
                                                                은평구에서 운영하는 시설로서 공익활동을 원하는 주민이라면<br>
                                                                누구나 이용하실 수 있는 장소입니다.
                                                            </p>
                                                            <div class="facilities_infobox" style="margin-top: 40px;">
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용시간</p>
                                                                    <p class="facilities_info_content">추후공지</p>
                                                                </div>
                                                                <div class="facilities_info_line" style="display:flex">
                                                                    <p class="facilities_info_title">이용대상</p>
                                                                    <p class="facilities_info_content">공익활동을 원하는 주민</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용방법</p>
                                                                    <p class="facilities_info_content">추후공지</p>
                                                                </div>
                                                                <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">위치안내</p>
                                                                    <p class="facilities_info_content">구산주택2층</p>
                                                                </div>
                                                                <!-- <div class="facilities_info_line">
                                                                    <p class="facilities_info_title">이용요금</p>
                                                                    <p class="facilities_info_content">추후공지</p>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="facilities_imgbox ">
                                                            <img class="facilities_img" src="@/assets/img/facilities/공익활동플랫폼.png">
                                                        </div>
                                                    </div>
                                                </swiper-slide>     
                                                <div class="swiper-pagination" slot="pagination"></div>
                                                <div class="swiper-button-prev" slot="button-prev"></div>
                                                <div class="swiper-button-next" slot="button-next"></div>
                                            </swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- -->   
    </div>
</template>

<script>

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            swiper: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                // pagination: {
                //     el: ".swiper-pagination",
                //     clickable: true
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            facilitesSwiperOptions: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                // pagination: {
                //     el: ".swiper-pagination",
                //     type: 'fraction'
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            isFirstPage: true,
        };
    },
    watch: {
        contract_type : {
            handler(type) {
                this.changeView(type)
            },
            deep: true
        }
    },
    mounted() {
        this.changeView(this.contract_type);
    },
    created() {},
	methods: {
        changeView(type) {
            if(type == '상가') { 
                document.getElementById('tab-1').checked = true;
            } else if(type == '편의시설') {
                document.getElementById('tab-2').checked = true;
            }
        },
        changeType(type) {
            this.$parent.contract_type = type;
        }
	},
    computed: {
    }
};
</script>

<style lang="scss">
#Store{position: relative; width: 100%;
    .swiperBox{background: #F5F5F5 0% 0% no-repeat padding-box; opacity: 1; width: 1920px; height: 946px; margin-left: -310px;
        .swiper{ width: 1445px; height: 946px;
            .swiper-slide {display: flex; justify-content: center; align-items: center; text-align: center; font-weight: bold; width: 1440px; padding: 60px;
                h1{position: absolute; left: 73px; top: 35px; font: normal normal bold 28px/41px Noto Sans KR; letter-spacing: 0px; color: #153E06; opacity:1; }
                img{width: 100%; height: 100%;}
            }
            .swiper-button-prev{background-image: url("../assets/icon/그룹 51.png"); background-size:27px;}
            .swiper-button-next{background-image: url("../assets/icon/그룹 52.png"); background-size:27px;}
            .swiper-button-prev:after{display: none;}
            .swiper-button-next:after{display: none;}
        }
    }
    .inner{
        .sub_visual.sub1bg{
            .in{width:100%; text-align: center; padding-top: 70px;} 
        }
    }
    .sub_content{
            // .main_title{position: relative; text-align: center;
            //     h2{margin-bottom: 60px; color: black; font-size: 38px; letter-spacing: -1px; font-weight: 500;} 
            //     h2:after{content: ''; display: block; margin: 15px auto 0; width: 35px; height: 3px; background: black;}
            // }
            .main input[type=radio] { display: none; }
            #tab-1:checked ~ .tab label:nth-child(1),
            #tab-2:checked ~ .tab label:nth-child(2) {box-shadow: inset 0 -3px 0 #153E06; color: #153E06; font: normal normal bold 22px/33px Noto Sans KR; opacity: 1;}
            .content > div { display: none;}

            #tab-1:checked ~ .content div:nth-child(1),
            #tab-2:checked ~ .content div:nth-child(2) {display: block;}
            .main { margin: 0 auto; max-width: 1330px;}
            .tab { overflow: hidden; border-bottom: 1px solid #E0E0E0; opacity: 1; text-align: center; width: 1902px; margin-left: -292px;}
            .tab label {width: 206px; font: normal normal normal 22px/33px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; cursor: pointer;text-align: center;text-align: center; padding: 15px 0; text-transform: uppercase; user-select: none; -webkit-user-select: none;}
            .tab label:visited{color: #153E06;}
            .content {min-height: 1000px;}
            .content > div{ line-height: 1.5; font-size: 17px;}

            h1{font: normal normal bold 28px/41px Noto Sans KR; letter-spacing: 0px; color: #153E06; margin-left:12px; position: absolute;}
            .imgbox{width: 1920px; height: 946px; background: #f5f5f5 0 0 no-repeat padding-box; margin-left: -310px; opacity: 1;
                .imgbox_sub{margin: 0 auto; width: 1445px; height: 946px; padding: 60px;} 
            }


        .main{margin: 0 auto; max-width: 1330px;  margin-bottom: 50px;}
        .content {min-height: 1000px;}
        .content > div{ line-height: 1.5; font-size: 17px;}

        .titlebox{
            .title{
                h2{font: normal normal bold 36px/43px S-Core Dream; letter-spacing: 2.16px; color: #153E06; opacity: 1; margin-bottom: 40px;}
            }
            .box{ background: var(--unnamed-color-f5f5f5) 0% 0% no-repeat padding-box;
                P{text-align: left; font-size: 28px; font-weight: 600; letter-spacing: 0px; color: #333333; opacity: 1; font: normal normal 28px/41px Noto Sans KR;}
                img{border: 1px solid #D5D5D5; opacity: 1; width: 1320px; height: 700px; margin-bottom: 10%;}
            }
        }
        .textbox{margin-bottom: 222px;
            table{width: 1320px; border-collapse: collapse; border-spacing: 0;  margin-top: 35px;
                .title{font: normal normal 500 18px/27px Noto Sans KR; letter-spacing: 2.16px; width: 20%; text-align: left; vertical-align: middle; color: #333333; opacity: 1;} 
                // td{font-size: 15px; overflow: hidden; word-break:normal; font: normal normal normal 14px/20px Noto Sans KR; letter-spacing: 0px; color: #333333;}
                tr{border-bottom: 1px solid #707070;}
                th{border-top: 2px solid #707070; border-bottom: 2px solid #707070;}
                .text{font: normal normal bold 16px/24px Noto Sans KR; text-align: center; letter-spacing: 1.92px; color: #333333; opacity: 1; height: 40px; opacity: 1}
                .txt{text-align: center; font: normal normal normal 16px/24px Noto Sans KR; letter-spacing: 0px; color: #626262; opacity: 1; height:30px;}
                .txt0{font: normal normal 16px/24px Noto Sans KR; text-align: center; color: #626262; opacity: 1; letter-spacing: 0px; height: 40px;}
            }
        }
        .contactSection{margin-bottom: 15%;}
    }
    .overview_facilities{margin-bottom: 308px;
        .facilities_row {margin-left: -291.5px; width: 1900px; height: 498px; background: #FDFDFD 0% 0% no-repeat padding-box;
            .facilities_area {width: 1700px; height: 700px;  margin-left: 201px; display: flex; position: relative; 
                .facilities_box {width:1700px; height: 700px; display: flex; margin-left: 50px;
                    .facilities_textbox {width: 747px; height: 100%; margin-top: 96px; margin-left: 42px; 
                        .facilities_sub_title {position: absolute; font: normal normal bold 14px/18px S-Core Dream; letter-spacing: 0.84px; color: #153E06; top: 75px;}
                        .facilities_title {font: normal normal bold 32px/47px Noto Sans KR; letter-spacing: 0px; color: #242424; opacity: 1;}
                        .facilities_content {font: normal normal normal 18px/25px Noto Sans KR; color: #242424;}
                        .facilities_infobox {
                            .facilities_info_line {display: flex; margin-bottom: 4px;
                                p.facilities_info_title {font: normal normal 500 18px/27px Noto Sans KR; width: 80px; letter-spacing: 2.7px; margin-bottom: 0px; color: #707070; opacity: 1;}
                                p.facilities_info_content {margin-left: 13px; font: normal normal normal 18px/27px Noto Sans KR; line-height: 27px; letter-spacing: -0.28px; margin-bottom: 0px; color: #707070; opacity: 1;}
                            }
                        }
                    }
                    .facilities_imgbox {
                        .facilities_img {width: 820px; height: 497px; margin-left: 40px;}
                    }
                }
                .swiper-pagination {position: absolute; width: 52px; color: #242424;; left: 81px; font: normal normal normal 16px/30px Noto Sans KR; z-index: 10; top: 27px; letter-spacing: 0.32px;}
                .swiper-button-prev {background-image: url("../assets/icon/그룹 51.png"); margin-left: 706px; background-size:27px; margin-top: 60px;}
                .swiper-button-prev::after {display:none;}
                .swiper-button-next {background-image: url("../assets/icon/그룹 52.png"); margin-right: 870px; background-size:27px;  margin-top: 60px;}
                .swiper-button-next::after {display:none;}
            }
        }
    }
    .show_sub_item {
        display: block !important;
    }
// p.sub-title {font: normal normal bold 14px/18px S-Core Dream; color:#2F4B98; margin-top: 84px; margin-bottom: 9px;}
}
</style>