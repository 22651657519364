<template>
    <div id="Guidance">
        <div class="inner">
            <div class="sub_visual sub1bg">
                <div class="in">
                    <!-- <p class="su_tit">상가정보</p> -->
                </div>
            </div>
        </div>
        <div class="wrap">
            <div class="overview_wrap">
                <!-- 단지개요 -->
                <div class="overview_outline">
                    <div class="overtit">
                        <div id="outline" class="main_title">
                            <!-- <p class="sub-title">단지안내</p> -->
                            <p>단지개요</p>
                        </div>
                        <div class="row" style="margin-top: 44px">
                            <div class="col-12 col-md-7">
                                <div class="textbox">
                                    <table>
                                        <tbody>
                                            <tr style="border-top: 0px">
                                                <td class="title" style="width:110px;">단지명</td>
                                                <td class="txt">구산주택(구산역 역세권 청년주택)</td>
                                            </tr>
                                            <tr>
                                                <td class="title">위치</td>
                                                <td class="txt">서울특별시 은평구 구산동 198-3(도로명주소)</td>
                                            </tr>
                                            <tr>
                                                <td class="title">대지면적</td>
                                                <td class="txt">2,177.6㎡</td>
                                            </tr>
                                            <tr>
                                                <td class="title">건축면적</td>
                                                <td class="txt">1,057.10㎡</td>
                                            </tr>
                                            <tr>
                                                <td class="title">연면적</td>
                                                <td class="txt">12,380.17㎡</td>
                                                <!-- <td class="txt">12,380.17㎡ <span class="txt-sub">(지상 10,627.34 ㎡, 지하 1,876.13 ㎡)</span></td> -->
                                            </tr>
                                            <tr>
                                                <td class="title">건폐율</td>
                                                <td class="txt">48.54%</td>
                                            </tr>
                                            <tr>
                                                <td class="title">용적률</td>
                                                <td class="txt">485.61% <span class="txt-sub">(*용적률산적용 연면적 10,574.71 ㎡)</span></td>
                                            </tr>
                                            <tr>
                                                <td class="title" style="width: 127px;">건축물규모</td>
                                                <td class="txt">세대수 238세대 <span class="txt-sub">(민간임대 217세대, 공공임대 21세대)</span>&nbsp;층수 지상19층/지하2층</td>
                                            </tr>
                                            <tr>
                                                <td class="title">주차대수</td>
                                                <td class="txt">97대</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="letter-spacing: -0.32px; color: #626262; font: normal normal normal 16px/30px Noto Sans KR;">※ 상기 면적은 준공시 일부 변경될 수 있습니다.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="imgbox">
                                    <div class="outline_img">
                                        <div class="outline_img_box" ></div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 편의시설 -->
                <!-- 단지특성 -->
                <div class="overview_around">
                    <div class="overtit">
                        <div id="around" class="main_title">
                            <!-- <p class="sub-title">단지안내</p> -->
                            <p>단지특성</p>
                        </div>
                    </div>
                    <div class="around">
                        <p class="around_title">교통환경</p>
                        <p class="around_sub">구산역 6호선, 단지 앞 버스정류장, 따릉이 위치</p>
                        <p class="around_content">
                            - 지하철 : 구산역 3번 출구로부터 250m 이내 역세권에 위치, 지하철을 이용하여 환승없이 디지털미디어시티(DMC), 마포, 공덕, 삼각지 등 지역으로의 접근가능. <br>
                            - 버스 : 단지 앞 버스정류소에는 서울역, 신용산, 숙명여대, 총신대, 연세대, 명지대 등을 환승 없이 갈 수 있습니다. <br>
                            - 카쉐어링 : 단지 내 주차장에서 언제나 나눔카, 그린카, 피플카를 사용할 수 있습니다. 
                        </p>
                    </div>
                    <div class="around">
                        <p class="around_title">편의시설</p>
                        <p class="around_sub">다양한 상권, 1층 상업시설 주상복합</p>
                        <p class="around_content">
                            - 아파트 1층에 카페, 베이커리, 편의점 등이 입점예정. <br>
                            - 인근에 중소형 마트 및 각종 요식업 및 생활편의시설 등이 위치해 있습니다. <br>
                            - 연신내 로데오거리가 1km이내에 위치하고 있고, 길건너에 전통시장(역촌중앙시장)이 있습니다. 
                        </p>
                    </div>
                    <div class="around">
                        <p class="around_title">거주환경</p>
                        <p class="around_sub">풀옵션, 합리적인 임대료, 우수한 교육환경</p>
                        <p class="around_content">
                            - 냉장고, 세탁기, 에어컨, 각종 붙박이장 등 풀옵션 주상복합아파트를 대한민국 국민이라면 누구나 합리적인 임대료에 거주 할 수 있습니다. <br>
                            - 구산근린공원, 백련산, 북한산 등이 인근에 위치하고 있고, 불광천을 따라 한강까지 자전거와 러닝을 즐길 수 있습니다. <br>
                            - 구산초, 은평중, 구산중, 예일여고 등 인근에 학군이 잘 형성되어 있습니다.
                        </p>
                    </div>
                    <div class="bg"></div>
                </div>
                <!-- 오시는길 -->
                <div class="overview_map">
                    <div class="overtit">
                        <div id="map" class="main_title">
                            <!-- <p class="sub-title">단지안내</p> -->
                            <p>오시는길</p>
                            <p class="map_sub_title">서울특별시 은평구 구산동 198-3(도로명주소)</p>
                        </div>
                    </div>
                    <div class="overmap" @click="kakaoMap">
                        <div id="kakaomap" style="width: 1320px; height: 700px;"></div>
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d558.7153564454037!2d126.91491034947886!3d37.61224643796363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c982c744205c3%3A0x5d775322a28a19ba!2z7ISc7Jq47Yq567OE7IucIOydgO2Pieq1rCDqtazsgrDrj5kgMTk4LTM!5e0!3m2!1sko!2skr!4v1645416340933!5m2!1sko!2skr" width="1320px" height="700px" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>


<script>
// import Vue from "vue";
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
// import 'swiper/swiper-bundle.css'
// import VueDaumMap from 'vue-daum-map'

export default {
    components: {
        // swiper,
        // swiperSlide
    },
    data() {
        return {
            map: null,
            marker: '',
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                }
            },
            facilitesSwiperOptions: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                // pagination: {
                //     el: ".swiper-pagination",
                //     type: 'fraction'
                // },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            }
        };
    },
    created() {
        this.kakaoMap();
    },
    mounted() {
    },
    methods: {
        kakaoMap() {
            if(!window.kakao || !window.kakao.map) {
                const script = document.createElement("script");
                script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=a69acb6da790635223d7e551b0fee098&autoload=false&libraries=drawing";
                /* global kakao */
                script.addEventListener("load", () => {
                    kakao.maps.load(this.loadMap)
                })
                document.head.appendChild(script);
            }
        },
        loadMap() {
            if(this.map == null) {
                const map = document.getElementById("kakaomap");
                const options = {
                    center: new kakao.maps.LatLng(37.61223363001231, 126.91489424944501),
                    level: 2,
                }

                this.map = new kakao.maps.Map(map, options);
                
                const marker = new kakao.maps.Marker({
                    map: this.map,
                    position: new kakao.maps.LatLng(37.61223363001231, 126.91489424944501),
                    title: "구산주택",
                    clickable: true,

                });

                kakao.maps.event.addListener(marker, 'click', () => {
                    window.open("http://kko.to/vHDyYNz-1")
                })
            }
        }
    },
    computed: {}
};
</script>

<style lang="scss">
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('../assets/fonts/S Core Dream/s-core-dream.css');

#Guidance{
    .inner{
        .sub_visual.sub1bg{
            .in{width:100%; text-align: center; padding-top: 145px;} 
        }
    }
    .wrap{width: 1330px; margin: 0 auto; height: auto; position:relative;
        .overview_wrap{position: relative; 
            .main_title{
                // p.sub-title {font: normal normal bold 14px/18px S-Core Dream; color:#2F4B98; margin-top: 84px; margin-bottom: 9px;}
                p{font: normal normal bold 36px/43px S-Core Dream; color: #153E06; text-align: left; opacity: 1; width: 100%; margin-bottom: 0; letter-spacing: 2.16px;}
                .line{margin-top: -20px; }
            }
            .row{margin-bottom: 100px;
                .col-md-7{padding-right: 45px;}
                .textbox{
                    table{width: 100%; border-collapse: collapse; border-spacing: 0; height: 408px;
                        .title{font: normal normal bold 24px/36px Noto Sans KR; letter-spacing: 2.88px; text-align: left; vertical-align: middle; color: #333333; opacity: 1;} 
                        .txt{padding-left: 17px; text-align: left; vertical-align: middle; font: normal normal normal 18px/27px Noto Sans KR; color: #333333; letter-spacing: 0px;}
                        span.txt-sub{color: #242424; font: normal normal 300 18px/27px Noto Sans KR;}
                        td{overflow: hidden; word-break:normal; font: normal normal normal 14px/20px Noto Sans KR; letter-spacing: 0px; color: #333333;}
                        tr{border-top: 1px solid #E0E0E0;}
                    }
                }
                .imgbox{
                    img{width: 100%;}
                }
            }
            .overview_outline{
                .swiper-container {
                    width: 520px;
                }
                .swiper-pagination {
                    width: 40px;
                    height: 24px;
                    position:absolute;
                    top: 0px;
                    left: 480px;
                }
                .swiper-pagination-bullet-active {
                    background-color:white;
                }
            }
            .overview_around{margin-top: 10%;
                div.around {
                    img{width: 66.5%; margin-left:33.5%; margin-top: -68px;}
                }
            }
            .overview_facilities{margin-top: 222px; 
                .facilities_row {margin-left: -291.5px; margin-top:40px; width: 1900px; height: 498px; background: #F5F5F5 0% 0% no-repeat padding-box;
                    .facilities_area {width: 1700px; height: 700px;  margin-left: 201px; display: flex; position: relative; 
                        .facilities_box {width:1700px; height: 700px; display: flex; margin-left: 50px;
                            .facilities_textbox {width: 747px; height: 100%; margin-top: 96px; margin-left: 42px;
                                .facilities_title {font: normal normal bold 32px/47px Noto Sans KR; letter-spacing: 0px; color: #242424; opacity: 1;}
                                .facilities_content {font: normal normal normal 18px/25px Noto Sans KR; color: #242424;}
                                .facilities_infobox {
                                    .facilities_info_line {display: flex; margin-bottom: 10px;
                                        p.facilities_info_title {border-right: 1px solid #F5F5F5; font: normal normal normal 20px/29px Noto Sans KR; width: 72px; letter-spacing: 2.1px; margin-bottom: 0px; color: #707070; opacity: 1;}
                                        p.facilities_info_content {margin-left: 13px; font: normal normal normal 18px/27px Noto Sans KR; line-height: 20px; letter-spacing: -0.28px; margin-bottom: 0px; color: #707070; opacity: 1;}
                                    }
                                }
                            }
                            .facilities_imgbox {
                                .facilities_img {width: 820px; height: 497px; margin-left: 40px;}
                            }
                        }
                        .swiper-pagination {position: absolute; width: 52px; color: #242424;; left: 81px; font: normal normal normal 16px/30px Noto Sans KR; z-index: 10; top: 27px; letter-spacing: 0.32px;}
                        .swiper-button-prev {background-image: url("../assets/icon/그룹 51.png"); margin-left: 706px; background-size:27px; margin-top: 60px;}
                        .swiper-button-prev::after {display:none;}
                        .swiper-button-next {background-image: url("../assets/icon/그룹 52.png"); margin-right: 870px; background-size:27px;  margin-top: 60px;}
                        .swiper-button-next::after {display:none;}
                    }
                }
            }
            .overview_around{margin-top: 230px; 
                .around{
                    .around_title{font: normal normal bold 32px/47px Noto Sans KR; letter-spacing: 0px; color: #242424; opacity: 1; margin-top: 33px; margin-bottom: 0px;}
                    .around_sub{font: normal normal normal 18px/27px Noto Sans KR; letter-spacing: -0.9px; color: #242424; opacity: 1; margin-bottom: 6px;}
                    .around_content{font: normal normal normal 14px/20px Noto Sans KR; letter-spacing: -0.28px; color: #707070; opacity: 1;}
                }
            }
            .overview_map{margin-top: 242px; 
                .main_title{margin-bottom: 40px;}
                .map_sub_title{font: normal normal normal 24px/36px Noto Sans KR; letter-spacing: 0px; color: #333333; opacity: 1; margin-top: 10px;}
                .overmap{width: 1320px; height: 700px; margin-bottom: 11%;}
            }
        }
    }
    .outline_img_box {
        width: 520px; height: 408px; background-image: url('../assets/img/main1.png'); background-size: cover; background-repeat: no-repeat; background-position: center center;
        // width: 520px; height: 420px; background: transparent url('../assets/img/main1.png') 0% 0% no-repeat padding-box;
    }
}

       
</style>